.dk-contactPrivacyMain{
    width:100%;
    max-width: 1600px;
    margin: auto;
    margin-top: 130px;
    height: 100vh;
    h3.title{
        margin: 0;
        padding: 0 0 30px;
        color: #243b4c;
        text-align: center;
        font-weight: 600;
        font-size: 26px;
        text-transform: uppercase;
    }
    .contact_detail{
        background: #38607c;
        margin: 30px -10px 30px 0;
        padding: 45px 30px 75px;
        h3{
            margin: 35px 0 25px;
            font-size: 18px;
            color: #fff;
            font-weight: 600;
        }
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin: 0;
                padding: 0 0 20px 0;
                color: #ddd;
                position: relative;
                i{
                    display: inline-block;
                    width: 18px;
                }
                a{
                    color: #ddd;
                    &:hover{
                        text-decoration:none;
                    }
                }
            }
        }
    }
    .card{
        background-color: #243b4c;
        background-clip: border-box;
        border-radius: 1px;
        border: 1px solid #243b4c;
        form{
            padding: 50px 30px;
            h2{
                margin: 15px 0;
                font-size: 18px;
                color: #fff;
            }
            input{
                height: 34px;
                padding: .1rem .5rem;
                font-size: 14px;
                background-color: #fff;
                line-height: 1.3;
                border: #fff;
            }
            textarea{
                background: #fff;
                height: 80px;
                resize: vertical;
            }
            p{
                color: #ddd;
                margin: 0;
                input[type="checkbox"]{
                    border-radius: 0;
                    margin-right: 5px;
                    width: 15px;
                }
                a{
                    display: inline-block;
                    margin: 0 4px;
                    color: #2e96b6;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            button{
                display: inline-flex;
                background: #f2f2f2;
                border-radius: 5px;
                padding: 8px 18px;
                color: #333;
                border:none;
                text-transform: uppercase;
                font-weight: 500;
                margin-top: 15px;
                &:hover{
                    background-color:#2e96b6;
                    color:#fff;
                    transition:all 0.5s;
                }
            }
        }
    }
}
.dk-erpBannerMain{
    position: relative;
    margin-top:50px;
    padding:0;
    .slick-dots{
        display: none !important;
    }
    .slick-arrow{
        display: block !important;
    }
    .slick-slide{
        div{
            display: block !important;
        }
    }
    button.slick-arrow.slick-next{
        right:45px;
        &::before{
            content: '';
            background: url('../../../assets/images/next.png') no-repeat;
            padding: 15px 21px;
            background-size: 50px;
        }
    }
    button.slick-arrow.slick-prev{
        left:45px;
        z-index: 99;
        transform: rotate(180deg);
        &::before{
            content: '';
            background: url('../../../assets/images/next.png') no-repeat;
            padding: 15px 21px;
            background-size: 50px;
        }
    }
    .dk-erpSliderCols{
        position: relative;
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
        &::after{
            content:'';
            width:100%;
            height: 100%;
            // background:rgba(0, 0, 0, 0.25);
            position:absolute;
            left: 0;
            top: 0;
            z-index: 9;
        }
        figure{
            position: absolute;
            top: 50%;
            left:30px;
            transform: translate(0, -50%);
            z-index: 99;
            text-align: left;
            color: #fff;
            width: 100%;
            max-width:510px;
            h2{
                color: #fff;
                font-weight: bolder;
                font-size: 34px;
            }
            a{
                display: inline-block;
                background:#2e97b6;
                text-decoration: none;
                padding:10px 25px;
                border-radius:5px;
                text-transform: uppercase;
                font-weight: 500;
                color: #fff;
                font-size: 15px;
                &:hover{
                    text-decoration: none;
                    background: #333;
                    color: #fff;
                    transition: all 0.5s;
                }
            }
        }
    }
}
.dk-erpHomeBlankSec{
    width: 100%;
    background-color: #2e97b5;
    padding: 50px 0 90px;
    .title{
        font-size: 28px;
        font-weight: 500;
        color: #fff;
        margin-bottom:25px;
        text-transform: uppercase;
    }
    .dk-videoBox{
        width: 100%;
        max-width: 650px;
        margin: auto;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        video{
            border-radius: 10px;
            width: 100%;
            height: 100%;
        }
        video::-webkit-media-controls {
            opacity: 1;
        }
        .dk-palyIcon{
            content: "";
            width: 100%;
            max-width: 80px;
            height: 80px;
            border-radius: 50%;
            background: rgba(60,151,181,.84);
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: #fff;
        }
    }
    .dk-videoMain{
        position: relative;
        width: 100%;
        max-width:650px;
        margin: auto;
        cursor: pointer;
        transition: all 0.5s;
        overflow: hidden;
        border-radius: 8px;
        &:hover{
            img{
                transform: scale(1.1);
                transition: all 0.5s;
            }
        }
        img{
            width: 100%;
            border-radius: 8px;
            transform: scale(1);
            transition: all 0.5s;
        }
        .dk-palyIcon{
            content: '';
            width: 100%;
            max-width: 80px;
            height: 80px;
            border-radius: 50%;
            background: rgba(60, 151, 181, 0.84);
            position: absolute;
            left: 50%;
            top: 50%;
            transform:translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: #fff;
        }
    }
}

.dk-homeshowTooltip{
    .arrow{
        &::before{
            border-top-color:rgba(0, 0, 0, 0.92) !important;
        }
    }
    .tooltip-inner{
        background-color:rgba(0, 0, 0, 0.92) !important;
    }
}

.dk-sendQueryMain{
    .modal-dialog{
        width: 100%;
        max-width:500px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .modal-content{
            box-shadow: none;
            border: none;
            border-radius: 5px;
            .modal-header{
                border-radius: 4px 4px 0 0;
                background: #2e97b6;
                padding: 8px 10px;
                .modal-title{
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    display: block;
                    width: 100%;
                }
                .close{
                    padding: 0;
                    opacity: 1;
                    color: #fff;
                    text-shadow: none;
                    font-weight: normal;
                    font-size: 28px;
                    position: absolute;
                    right: 7px;
                    top: 8px;
                    margin: 0;
                    outline: none;
                }
            }
            .dk-sendQuery-body{
                padding: 12px 25px;
                form{
                    label{
                        width: 100%;
                        input{
                            height: 38px;
                            border:1px solid #ddd !important;
                            box-shadow: none !important;
                            border-radius: 3px;
                        }
                        input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: rgb(189, 189, 189);
                        }
                        input::-moz-placeholder { /* Firefox 19+ */
                            color: rgb(189, 189, 189);
                        }
                        input:-ms-input-placeholder { /* IE 10+ */
                            color: rgb(189, 189, 189);
                        }
                        input:-moz-placeholder { /* Firefox 18- */
                            color: rgb(189, 189, 189);
                        }

                        textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: rgb(189, 189, 189);
                        }
                        textarea::-moz-placeholder { /* Firefox 19+ */
                            color: rgb(189, 189, 189);
                        }
                        textarea:-ms-input-placeholder { /* IE 10+ */
                            color: rgb(189, 189, 189);
                        }
                        textarea:-moz-placeholder { /* Firefox 18- */
                            color: rgb(189, 189, 189);
                        }
                        textarea{
                            height: 100px;
                            border:1px solid #ddd !important;
                            box-shadow: none !important;
                            border-radius: 3px;
                            resize: vertical;
                        }
                    }
                    .dk-submitBtn{
                        display: inline-block;
                        background: #2e97b6;
                        border:none;
                        color: #fff;
                        padding: 8px 20px;
                        border-radius: 5px;
                        outline: none;
                        &:hover{
                            opacity: 0.8;
                            transition:all 0.5s;
                        }
                    }
                }
            }
        }
    }
}

.dk-videoModal{
    transform: none !important;
    .modal-dialog{
        transform: none !important;
        width: 100%;
        max-width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: #000;
        margin: 0;
        .modal-content{
            border-radius: 0;
            border:none;
            box-shadow: none;
            background: #000;
            height: 100%;
            .modal-header{
                border:none;
                display: flex;
                justify-content:flex-end;
                .close{
                    color: #fff;
                    text-shadow: none;
                    font-weight: normal;
                    font-size: 30px;
                    opacity: 1;
                    margin: 0;
                    padding: 0;
                    float: right;
                }
            }
            .modal-body{
                display: flex;
                justify-content: center;
                iframe{
                    width: 70%;
                    height: 70vh;
                    margin-top: 25px;
                }
            }
        }
    }
}

.dk-applicationMain{
    padding:50px 0;
    .dk-appColsMain{
        width: 100%;
        max-width: 1080px;
        margin: auto;
        .col-md-2{
            padding-right: 10px;
            padding-left: 10px;
            flex: 0 0 17.666667%;
            max-width: 17.666667%;
        }
    }
    .title{
        display: block;
        margin-top: 0;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #333;
        margin-bottom:30px;
        h4{
            font-size: 16px;
            font-weight: normal;
        }
    }
    .dk-portalBox{
        width: 100%;
        min-height: 140px;
        background: #eee;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom:20px;
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            width: 100%;
            height: 140px;
            &:hover{
                text-decoration: none;
                .dk-Portalicons{
                    img{
                        transition: all .5s;
                        -webkit-transform: scale(.88);
                        transform: scale(.88);
                    }
                }
            }
        }
        div{
            display: block;
            width: 100%;
        }
        .dk-Portalicons{
            text-align: center;
            margin-bottom: 10px;
            img{
                width: 100%;
                max-width: 40px;
                transition: all .5s;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }
        .dk-PortalTitle{
            font-size: 17px;
            text-transform: capitalize;
            font-weight: 400;
            display: block;
            text-align: center;
        }
    }
    .dk_portalBox_manaul{
        background: #3e4c60;
    }
    .dk_portalBox_1{
        background: #a43b63;
    }
    .dk_portalBox_2{
        background: #297483;
    }
    .dk_portalBox_3{
        background: #535255;
    }
    .dk_portalBox_4{
        background: #144773;
    }
    .dk_portalBox_5{
        background: #26596D;
    }
    .dk_portalBox_6{
        background: #3e4c60;
    }
    .dk_portalBox_7{
        background: #2e75b6;
    }
    .dk_portalBox_8{
        background: #38607c;
    }
    .dk_portalBox_9{
        background: #4C5F59;
    }
    .dk_portalBox_10{
        background: #2b8faf;
    }
    .dk_portalBox_11{
        background:#cf7c49;
    }
    .dk_portalBox_12{
        background: #5c737d;
    }
    .dk_portalBox_13{
        background: #A84D4D;
    }
    .dk_portalBox_14{
        background: #483252;
    }
    .dk_portalBox_15{
        background: #57496d;
    }
}

.dk-benifitsMain{
    background: #fff;
    padding:50px 0 70px;
    color: #333;
    min-height:500px;
    display: flex;
    align-items: center;
    .title{
        display: block;
        margin-top: 0;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #333;
        margin-bottom: 30px;
        text-transform: uppercase;
        h4{
            font-size: 16px;
            font-weight: normal;
            text-transform: initial;
        }
    }
    .dk-clientBox{
        margin-top:55px;
        .dk-clientLine{
            width: 100%;
            max-width:3px;
            min-height: 100%;
            background: #2e97b6;
            position: relative;
            span{
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #2e97b6;
                color: #fff;
                text-align: center;
                line-height: 32px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                font-size:20px;
            }
        }
        .dk-clientAroundCols{
            width: 100%;
            padding:0;
            .sbtitle{
                font-size:22px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 12px;
            }
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                li{
                    display: flex;
                    padding: 8px 0;
                    font-size: 15px;
                    i{
                        display: inline-block;
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        color: #f2f2f2;
                        margin-right: 10px;
                    }
                    i.fa-times{
                        color:#E2445B;
                    }
                    i.fa-check{
                        color: green;
                    }
                }
            }
        }
        
    }
}

.dk-contactMain{
    background: #3c97b6;
    padding: 50px 0 70px;
    .title{
        display: block;
        margin-top: 0;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #fff;
        margin-bottom:35px;
        text-transform: uppercase;
        h4{
            font-size: 16px;
            font-weight: normal;
            text-transform: initial;
        }
    }
    form{
        width: 100%;
        max-width: 550px;
        background: #fff;
        border-radius: 10px;
        padding: 30px 40px;
        margin: auto;
        border: 1px solid #e6e6e6;
        min-height: 415px;
        .col-md-6{
            padding-left: 10px;
            padding-right: 10px;
        }
        .col-md-12{
            padding-left: 10px;
            padding-right: 10px;
        }
        label{
            width: 100%;
            input{
                border-radius: 3px;
                height: 35px;
                border:1px solid #ddd !important;
                box-shadow: none !important;
            }
            input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #ccc;
            }
            input::-moz-placeholder { /* Firefox 19+ */
                color: #ccc;
            }
            input:-ms-input-placeholder { /* IE 10+ */
                color: #ccc;
            }
            input:-moz-placeholder { /* Firefox 18- */
                color: #ccc;
            }
            textarea{
                border-radius: 3px;
                height:100px;
                resize: vertical;
                border:1px solid #ddd !important;
                box-shadow: none !important;
            }
            textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #ccc;
            }
            textarea::-moz-placeholder { /* Firefox 19+ */
                color: #ccc;
            }
            textarea:-ms-input-placeholder { /* IE 10+ */
                color: #ccc;
            }
            textarea:-moz-placeholder { /* Firefox 18- */
                color: #ccc;
            }
        }
        .submitBtn{
            display: inline-block;
            background: #2e97b6;
            border: none;
            outline: none;
            color: #fff;
            text-transform: uppercase;
            font-weight: 500;
            padding: 7px 18px;
            border-radius: 5px;
            &:hover{
                background: #406a86;
                transition: all 0.5s;
            }
        }
    }
    .dk-contactText{
        background: #fff;
        border-radius: 5px;
        padding:80px 35px;
        border: 1px solid #e6e6e6;
        min-height: 415px;
        .sbtitle{
            font-weight: 600;
            font-size:20px;
        }
        ul{
            list-style: none;
            padding: 25px 0;
            li{
                list-style-type: none;
                display: block;
                padding:5px 0;
                color: #333;
                a{
                    color: #333;
                    &:hover{
                        text-decoration: none;
                    }
                }
                i{
                    display: inline-block;
                    width:18px;
                    text-align:left;
                }
            }
        }
        .dk-contactSocial{
            a{
                display: inline-block;
                width: 30px;
                margin-right:10px;
            }
        }
    }
}

.dk-pricingMain{
    padding: 50px 0 70px;
    background: #2e97b6;
    .title{
        display: block;
        margin-top: 0;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 30px;
        text-transform: uppercase;
        h4{
            font-size: 16px;
            font-weight: normal;
            text-transform: initial;
        }
    }
    .dk-pricingBox{
        min-height: 400px;
        .headtitle{
            font-size: 28px;
            font-weight:500;
            background: #000;
            padding:35px 0 100px;
            color: #fff;
            border-radius: 10px 10px 0 0;
            text-align: center;
            position: relative;
            z-index: 99;
            h3{
                font-size: 20px;
                text-transform: uppercase;
                margin: 0;
            }
        }
        .bg_standard{
            background:#5c737d !important;
        }
        .bg_premium{
            background: #38607c !important;
        }
        .bg_enterprise{
            background: #a43b63 !important;
        }
        .dk-priceCols{
            background: #f2f2f2;
            border-radius: 0 0 10px 10px;
            border:1px solid #ddd;
            min-height: 463px;
            .dk-priceBoxImg{
                width: 120px;
                height: 120px;
                background:#5c737d;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                position: relative;
                border:1px solid #c3c3c3;
                margin-top: -65px;
                z-index: 99;
                .dk-priceCircle{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    border-radius: 50%;
                    position: absolute;
                    right: 10px;
                    top: 2px;
                }
                img{
                    width:45px;
                }
            }
            .dk-price{
                font-size: 42px;
                font-weight: 600;
                display: block;
                text-align: center;
                padding:15px 0;
                span{
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .dk-price.dk-enquery{
                font-size: 36px;
            }
            .dk-priceList{
                padding:0;
                margin: 0;
                list-style: none;
                li{
                    display: flex;
                    justify-content: center;
                    list-style-type: none;
                    border-bottom: 1px solid #ddd;
                    padding:0;
                    text-align: center;
                    font-size: 15px;
                    div{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        max-width: 100%;
                        padding-left: 50px;
                    }
                    span{
                        display: flex;
                        border-radius: 3px;
                        font-size: 14px;
                        padding: 1px 0;
                        color: #333;
                        border-left: 1px solid #ddd;
                        width: 100%;
                        max-width: 70px;
                        height:40px;
                        align-items: center;
                        justify-content: center;
                        i{
                            color: #8c8c8c;
                        }
                    }
                    span.yes{
                        background: green;
                    }
                    span.no{
                        background: #E2445B;
                    }
                    &:first-child{
                        border-top:1px solid #ddd;
                    }
                }
            }
            .dk-priceBookBtn{
                padding:30px 0 35px;
                display: flex;
                justify-content: center;
                a{
                    display: inline-block;
                    background: #5c737d;
                    border:2px solid #5c737d;
                    padding: 8px 25px;
                    border-radius: 5px;
                    color: #fff;
                    font-weight:500;
                    text-transform: uppercase;
                    transition: all 0.5s;
                    &:hover{
                        text-decoration: none;
                        background: transparent !important;
                        border-color: #5c737d;
                        color: #5c737d;
                        transition: all 0.5s;
                    }
                }
                a.bg_premium{
                    display: inline-block;
                    background: #38607c;
                    border:2px solid #38607c;
                    padding: 8px 25px;
                    border-radius: 5px;
                    color: #fff;
                    font-weight:500;
                    text-transform: uppercase;
                    transition: all 0.5s;
                    &:hover{
                        text-decoration: none;
                        background: transparent;
                        border-color: #38607c;
                        color: #38607c;
                        transition: all 0.5s;
                    }
                }
                a.bg_enterprise{
                    display: inline-block;
                    background: #a43b63;
                    border:2px solid #a43b63;
                    padding: 8px 25px;
                    border-radius: 5px;
                    color: #fff;
                    font-weight:500;
                    text-transform: uppercase;
                    transition: all 0.5s;
                    &:hover{
                        text-decoration: none;
                        background: transparent;
                        border-color: #a43b63;
                        color: #a43b63;
                        transition: all 0.5s;
                    }
                }
            }
        }
        
    }
}

.dk-testimonialMain{
    background: #fff;
    padding:50px 0 70px;
    .title{
        display: block;
        margin-top: 0;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #333;
        margin-bottom:40px;
        text-transform: uppercase;
        h4{
            font-size: 16px;
            font-weight: normal;
            text-transform: initial;
        }
    }
    .dk-testimonialSliderMain{
        .slick-slider{
            .slick-arrow{
                width: 50px;
                top: 60px;
                transition: all 0.5s;
                transform: scale(1);
                opacity: 0.7;
                &:hover{
                    transition: all 0.5s;
                    transform: scale(1.2);
                }
            }
            .slick-slide{
                padding: 0 5px;
            }
            .slick-prev{
                left: -50px;
                &::before{
                    content: "";
                    display: inline-block;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    font-size: 22px;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    color: #fff;
                    background: url('../../../assets/images/navarrowleft.png') no-repeat;
                    background-size: 50px;
                    padding: 26px 22px;
                    transform: rotate(180deg);
                }
            }
            .slick-next{
                right: -50px;
                &::before{
                    content: "";
                    display: inline-block;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    font-size: 22px;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    color: #fff;
                    background: url('../../../assets/images/navarrow.png') no-repeat;
                    background-size: 50px;
                    padding: 26px 22px;
                }
            }
            .dk-testimonialCols{
                .dk-testimonialtext{
                    background: #f2f2f2;
                    padding: 25px;
                    border-radius:5px;
                    border:1px solid #eaeaea;
                    position: relative;
                    &::after{
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 30px 30px 0;
                        border-color: transparent #f2f2f2 transparent transparent;
                        line-height: 0px;
                        _border-color: #000000 #f2f2f2 #000000 #000000;
                        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                        position: absolute;
                        left: 4px;
                        bottom: -27px;
                    }
                }
                .dk-testimonialImg{
                    margin-top: 32px;
                    margin-left: 30px;
                    .dk-testiImg{
                        margin-right: 10px;
                        img{
                            width: 72px;
                            height: 72px;
                            border-radius: 50%;
                            border:2px solid #2e97b5;
                            padding:2px;
                        }
                    }
                    .dk-testiText{
                        line-height:20px;
                        .testiTitle{
                            font-weight: 600;
                            font-size: 18px;
                            text-transform: uppercase;
                        }
                        small{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        .dk-viewAllBtn{
            display: flex;
            justify-content: center;
            margin-top: 60px;
            a{
                display: inline-block;
                background: #2e97b6;
                text-decoration: none;
                padding: 10px 25px;
                border-radius: 5px;
                text-transform: uppercase;
                font-weight: 500;
                color: #fff;
                font-size: 15px;
                &:hover{
                    text-decoration: none;
                    background: #333;
                    color: #fff;
                    transition: all 0.5s;
                }
            }
        }
    }
}

@media(max-width:991px){
    .dk-pricingMain .dk-pricingBox .dk-priceCols .dk-price.dk-enquery{
        font-size: 24px;
    }
    .dk-pricingMain .dk-pricingBox .dk-priceCols .dk-price{
        font-size: 24px;
    }
    .dk-pricingMain .dk-pricingBox .dk-priceCols .dk-priceList li{
        font-size: 14px;
    }
    .dk-pricingMain .dk-pricingBox .dk-priceCols .dk-priceList li div{
        padding-left: 15px;
    }
    .dk-benifitsMain .dk-clientBox .d-flex{
        flex-wrap: wrap;
    }
    .dk-benifitsMain .dk-clientBox .dk-clientLine{
        width: 100%;
        min-width: 100%;
        min-height: auto;
        height: 3px;
        margin: 40px 0;
    }
    footer .dk-footerCols{
        flex-wrap: wrap;
    }
    footer .dk-footerCols .dk-footerBox{
        width: 100%;
        margin-bottom: 25px;
    }
    
    .dk-applicationMain .dk-portalBox{
        min-height: 120px;
    }
    .dk-applicationMain .dk-portalBox a{
        height: 120px;
    }
    .dk-contactMain{
        .col-md-6.pl-4{
            padding-left: 15px !important;
        }
        .col-md-6.pr-4{
            padding-right: 15px !important;
        }
        .dk-contactText{
            margin-bottom: 30px;
        }
    }
    .dk-pricingMain{
        padding: 30px 0 20px;
        .dk-pricingBox{
            margin-bottom:25px;
        }
    }
    .dk-applicationMain .dk-appColsMain .col-md-2{
        flex: 25%;
        max-width: 25%;
    }
    .dk-applicationMain .dk-appColsMain{
        padding: 0 20px;
        .row{
            justify-content: flex-start !important;
        }
    }
    .dk-applicationMain .dk-portalBox .dk-PortalTitle{
        font-size: 15px;
    }
    .dk-benifitsMain{
        padding:30px 0 30px;
    }
    .dk-contactMain{
        padding: 30px 0 40px;
    }
    .dk-benifitsMain .dk-clientBox .dk-clientLine span i {
        transform: rotate(90deg);
    }
}

@media(max-width:767px){
    .dk-applicationMain .dk-appColsMain .col-md-2{
        flex: 50%;
        max-width: 50%;
    }
    .dk-applicationMain .title{
        font-size: 22px;
    }
    .dk-applicationMain .title h4 {
        font-size: 14px;
    }
    .dk-pricingMain .title{
        font-size: 22px;
    }
    .dk-pricingMain .title h4{
        font-size: 14px;
    }
    .dk-benifitsMain .title{
        font-size: 22px;
    }
    .dk-benifitsMain .title h4{
        font-size: 14px;
    }
    .dk-benifitsMain .dk-clientBox .dk-clientAroundCols .sbtitle{
        font-size:18px;
    }
    .dk-contactMain .title{
        font-size: 22px;
    }
    .dk-contactMain .title h4{
        font-size: 14px;
    }
    .dk-testimonialMain .title{
        font-size: 22px;
    }
    .dk-testimonialMain .title h4{
        font-size: 14px;
    }
    .dk-testimonialSliderMain{
        .slick-dots{
            bottom: -45px;
        }
    }
    footer .dk-footerCopyright{
        padding: 15px 0 0 0;
    }
    .dk-erpBannerMain .dk-erpSliderCols figure{
        max-width: 300px;
        left: 15px;
        h2{
            font-size: 18px;
        }
        a{
            padding: 6px 15px;
            font-size: 14px;
        }
    }
    .dk-erpBannerMain .dk-erpSliderCols{
        img{
            height:250px;
        }
    }
    .dk-erpHomeBlankSec{
        padding: 30px 15px 40px;
        .title{
            font-size: 22px;
        }
    }
    .dk-pricingMain .dk-pricingBox .dk-priceCols{
        min-height: auto;
    }
    .dk-applicationMain {
        padding: 30px 0;
    }
}

@media(max-width:560px){
    .dk-testimonialMain .dk-testimonialSliderMain .slick-slider .slick-arrow{
        display: none !important;
    }
    .dk-testimonialMain .dk-testimonialSliderMain .slick-slider{
        .slick-dots{
            li{
                width: 12px;
                height: 12px;
                margin: 0 3px;
                button{
                    width: 12px;
                    height: 12px;
                    background: #2e97b5;
                    border-radius: 50%;
                    &::before{
                        display: none;
                    }
                }
            }
            li.slick-active{
                button{
                    background: #000;
                }
            }
        }
    }
    .dk-testimonialMain {
        background: #fff;
        padding:30px 0 80px;
    }
   
}
.dk-privacyMain{
    width: 100%;
    max-width: 1600px;
    margin: 50px auto;
    margin-top:90px;
    padding: 0 18px;
    h3.title{
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        background-color: #243b4c;
        padding: 14px 10px;
        text-align: center;
        border-radius:5px 5px 0 0;
        border-bottom: 1px solid #4d5a6b;
    }
    .dk-privacyText-body{
        background: #243b4c;
        padding: 25px;
        border-radius: 0 0 5px 5px;
        color: #fff;
    }
}
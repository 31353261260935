header{
    background: #fff;
    box-shadow:0 0 7px rgba(0, 0, 0, 0.22);
    padding:8px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    .dk-headerMain{
        .dk-logo{
            width: 100%;
            max-width: 140px;
        }
        .dk-menu{
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                background: #eaeaea;
                border-radius: 5px;
                box-shadow: 1px 1px 4px #999;
                width:182px;
                justify-content: space-between;
                @media(max-width:991px){
                    background: #17a2b8;
                }
                li{
                    list-style-type: none;
                    margin-right:30px;
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        display: inline-block;
                        font-size: 15px;
                        font-weight:400;
                        color: #516378;
                        &:hover{
                            text-decoration: none;
                            color: #2d96b6;
                        }
                        @media(max-width:991px){
                            color: #fff !important;
                        }
                    }
                }
                .active{
                    a{
                        background: #2d96b6;
                        border-radius: 0 5px 5px 0!important;
                        color: #fff !important;
                    }
                    @media(max-width:991px){
                        a{
                            border-radius: 0 !important;
                        }
                    }
                }
                .signup-active{
                    a{
                        background: #2d96b6;
                        border-radius: 5px 0 0 5px !important;
                        color: #fff !important;
                    }
                    @media(max-width:991px){
                        a{
                            border-radius: 0 !important;
                        }
                    }
                }
                .dk-menuBtn{
                    margin-right: 0;                    
                    a{
                        color: #333;
                        width: 100%;
                        text-align: center;
                        border-radius: 0;
                        padding:6px 15px;
                        font-weight:500;
                        color: #325f7d;
                        transition: all 0.3s;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        border-right: 1px solid #c7c7c7;
                        i{
                            display: inline-block;
                            margin-right: 5px;
                        }
                        &:hover{
                            transition: all 0.3s;
                            color: #2d96b6;
                        }
                    }
                    @media(max-width:991px){
                        width: 100%;
                        a{
                            text-align: center;
                            width: 100%;
                            display: block;
                            display: block;
                            background: #fff;
                            border-radius: 0;
                            color: #333 !important;
                        }
                    }
                }

                .dk-loginBtn{
                    margin-right: 0;
                    a{       
                        display: flex;
                        align-items: center;
                        border-right: none;
                        i{
                            display: inline-block;
                        }                 
                        &:hover{
                            transition: all 0.3s;
                            color: #2d96b6;
                        }
                    }
                    @media(max-width:991px){
                        width: 100%;
                        a{
                            text-align: center;
                            width: 100%;
                            display: block;
                        }
                    }
                }
                .dk-signupBtn{
                    a{
                        &:hover{
                            transition: all 0.3s;
                            color: #2d96b6;
                        }
                    }
                }
            }
        }
        .dk-homeNav{
            margin-right:0;
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                li{
                    list-style-type: none;
                    a{
                        display: inline-block;
                        color: #333;
                        margin: 0 18px;
                        font-size: 14px;
                        cursor: pointer;
                        &:hover{
                            text-decoration: none;
                            color: #2e97b6;
                        }
                        @media(max-width:991px){
                            margin: 0;
                            display: block;
                            border-bottom: 1px solid #297d96;
                            padding: 10px 15px !important;
                        }
                    }
                }
            }
        }
    }
    .dk-navMainContainer{
        display:flex;
        align-items: center;
        width: 69%;
        justify-content: space-between;
        @media(max-width:1366px){
            width: 75%;
        }
        @media(max-width:1199px){
            width:80%;
        }
    }
    .dk-mobileNavBtn{
        display: none;
    }
    .dk-closeBtn{
        display: none;
    }
    @media(max-width:991px){
        .dk-mobileNavBtn{
            display: block;
            border: none;
            outline: none;
            display: inline-block;
            width: 30px;
            height: 30px;
            background: transparent;
            line-height: 5px;
            text-align: left;
            span{
                width: 30px;
                height:2px;
                display: inline-block;
                background: #333;
                &:nth-child(02){
                    width: 22px;
                }
                &:nth-child(03){
                    width:15px;
                }
            }
        }
        .dk-navMainContainer.openMenu{
            transition: all 0.5s;
            left:-101%;
            position: fixed;
            top: 0;
        }
        .dk-navMainContainer{
            position: fixed;
            top: 0;
            left: 0;
            width:100%;
            transition: all 0.5s;
            height: 100vh;
            background: #212529;
            flex-wrap: wrap;
            align-items: flex-start;
            .dk-homeNav{
                width: 100%;
                margin-top:73px;
                ul{
                    flex-wrap: wrap;
                    li{
                        display: block;
                        width: 100%;
                        a{
                            color: #fff;
                            display: block;
                            width: 100%;
                            padding: 5px 0;
                            background: #2e97b6;
                            cursor: pointer;
                            &:hover{
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .dk-menu{
                position: absolute;
                top:40px;
                left: 0;
                width: 100%;
                background: #eaeaea;
                ul{
                    border-radius: 0;
                    box-shadow: none;
                    width: 100%;
                    justify-content: start;
                    position: relative;
                }
                
            }
            .dk-closeBtn{
                display: block;                    
                position: absolute;
                right: 0;
                top: 0;
                z-index: 99;
                width: 35px;
                border-left: 1px solid #525a63;
                height: 40px;
                line-height:40px;
                text-align: center;
                background: #212529;
                button{
                    border: none;
                    outline: none;
                    background: #212529;
                    color: #fff;
                    padding: 0;
                    width: 35px;
                }
            }
        }
    }
}


.dk-termsMain{
    width: 100%;
    max-width: 1600px;
    margin: 50px auto;
    margin-top:80px;
    padding: 0 18px;
    h3.title{
        text-align: center;
        margin-bottom: 10px;
        font-size: 26px;
    }
    .dk-faqContent{
        background:#24475f;
        border-radius: 5px;
        .MuiAccordion-root{
            margin: 0 !important;
            border-radius: 0;
            box-shadow: none;
            border:none;
            border-bottom: 1px solid #325c79;
            color: #fff;
            &:first-child{
                .MuiButtonBase-root{
                    border-radius: 5px 5px 0 0;
                }
            }
            &:last-child{
                border-bottom:none;
                .MuiAccordionDetails-root{
                    border-radius: 0 0 5px 5px;
                }
                .MuiButtonBase-root.Mui-expanded{
                    border-radius: 0;
                }
                .MuiButtonBase-root{
                    border-radius:0 0 5px 5px;
                }
            }
            .MuiButtonBase-root{
                min-height: auto;
                background: #1f3748;
                padding:10px 15px;
                .MuiAccordionSummary-content{
                    margin: 0;
                    white-space: nowrap;
                    .MuiTypography-root{
                        color: #fff;
                        font-size: 15px;
                        font-weight: 500;
                        text-transform: uppercase;
                        font-family: 'Poppins', sans-serif !important;
                    }
                }
                .MuiButtonBase-root{
                    padding: 0;
                    background: transparent;
                    span{
                        color: #fff;
                        font-size: 16px;
                        svg{
                            font-size:28px;
                        }
                    }
                }
            }
            
            .MuiCollapse-root{
                .MuiCollapse-wrapper{
                    .MuiCollapse-wrapperInner{
                        .MuiAccordionDetails-root{
                            padding:15px !important;
                            background:#24475f;
                            color:#f2f2f2;
                            p{
                                font-family: Open Sans, sans-serif;
                                font-size: 14px;
                                line-height: 21px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
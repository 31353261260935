.dk-faqMain {
    margin-top: 80px;
    padding: 0 50px;
    h3.title{
        text-align: center;
        margin:0;
        margin-bottom: 12px;
    }
    .dk-faqColsMain{
        padding-bottom:40px;
        .dk-faqTabs{
            display: flex;
            .nav-tabs{
                border:none;
                width: 100%;
                max-width:300px;
                padding-right:50px;
                a{
                    display: block;
                    width: 100%;
                    background: #24475f;
                    color: #fff;
                    border-bottom: 1px solid #607f92 !important;
                    border:none;
                    position: relative;
                    margin: 0;
                    border-radius: 0;
                    padding: 10px 15px;
                    &::before{
                        content: "\f059";
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        font-weight: bold;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        margin-right:8px;
                        font-size: 17px;
                        position: relative;
                        top: 1px;
                    }
                    &:first-child{
                        border-radius: 5px 5px 0 0;
                    }
                    &:last-child{
                        border-radius:0 0 5px 5px;
                        border-bottom: none;
                    }
                }
                a.active{
                    background: #1f3748;
                    &::after{
                        content: "";
                        width: 0;
                        height: 0;
                        border-bottom: 10px solid transparent;
                        border-top: 10px solid transparent;
                        border-left: 12px solid #1f3748;
                        position: absolute;
                        top: 12px;
                        right: -12px;
                    }
                }
            }
            .dk-faqContent{
                background:#24475f;
                border-radius: 5px;
                .MuiAccordion-root{
                    margin: 0 !important;
                    border-radius: 0;
                    box-shadow: none;
                    border:none;
                    border-bottom: 1px solid #325c79;
                    color: #fff;
                    &:first-child{
                        .MuiButtonBase-root{
                            border-radius: 5px 5px 0 0;
                        }
                    }
                    &:last-child{
                        border-bottom:none;
                        .MuiAccordionDetails-root{
                            border-radius: 0 0 5px 5px;
                        }
                        .MuiButtonBase-root.Mui-expanded{
                            border-radius: 0;
                        }
                        .MuiButtonBase-root{
                            border-radius:0 0 5px 5px;
                        }
                    }
                    .MuiButtonBase-root{
                        min-height: auto;
                        background: #1f3748;
                        padding:10px 15px;
                        .MuiAccordionSummary-content{
                            margin: 0;
                            white-space: nowrap;
                            .MuiTypography-root{
                                color: #fff;
                                font-size: 15px;
                                font-weight: normal;
                            }
                        }
                        .MuiButtonBase-root{
                            padding: 0;
                            background: transparent;
                            span{
                                color: #fff;
                                font-size: 16px;
                                svg{
                                    font-size:28px;
                                }
                            }
                        }
                    }
                    
                    .MuiCollapse-root{
                        .MuiCollapse-wrapper{
                            .MuiCollapse-wrapperInner{
                                .MuiAccordionDetails-root{
                                    padding:15px !important;
                                    background:#24475f;
                                    color:#f2f2f2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,body{
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
}

body{
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  height: auto !important;
}

html {
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  overflow: hidden;
  overflow-y:auto;
  height: 100%;
}

html::-webkit-scrollbar {
  width: 0px;
}
 
html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}
 
html::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0 solid slategrey;
}

h1, h2, h3, h4, h5{
  font-family: 'Poppins', sans-serif !important;
}
footer{
    background: #2e97b6;
    padding: 30px 70px 15px;
    .dk-footerCols{
        display: flex;
        justify-content: space-between;
        .dk-footerBox{
            color: #f2f2f2;
            .title{
                font-size: 15px;
                font-weight: 600;
                display: block;
                margin-bottom:12px;
            }
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    list-style-type: none;
                    a{
                        display: block;
                        font-size: 13px;
                        color: #fff;
                        margin-top:8px;
                        text-decoration: none;
                        i{
                            display: inline-block;
                            width:18px;
                        }
                        &:hover{
                            text-decoration: none;
                            color:#000;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
    .dk-footerCopyright{
        color: #f2f2f2;
        padding:50px 0 0;
        font-weight:300;
        font-size:12px;
        @media(max-width:767px){
            padding:10px 0 0;    
        }
        small{
            display: block;
            font-weight: 300;
        }
    }
}

@media(max-width:767px){
    footer{
        padding: 30px 0 15px;
    }
}
.dk-loginMain{
    background: #fff;
    padding:15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    .dk-loginContainer{
        width: 100%;
        max-width:450px;
        background: #fff;
        padding: 25px;
        border-radius: 5px;
        margin-top: -50px;
        .logo{
            text-align: center;
            margin-bottom:20px;
            img{
                width: 175px;
                height: 44px;
            }
        }
        h3.title{
            text-align: center;
            font-size:26px;
            font-weight:300;
            color: #000;
        }
        .mx-100{
            max-width: 100%;
        }
        h4{
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            margin-bottom: 15px;
        }

        form{
            width: 100%;
            max-width:100%;
            margin:15px auto;
            margin-bottom: 20px;
            
            .loginNext{
                input{
                    padding-right: 150px;
                }
            }
            .dk-eyeICon{
                color: #928c8c;
                font-size: 14px;
                svg{
                    width:20px;
                }
            }
            label{
                width: 100%;
                position: relative;
                .domainAppendSpan{
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: #f1f1f4;
                    height: 40px;
                    font-size: 13px;
                    font-weight: 400;
                    padding: 0 10px;
                    border-radius: 0 3px 3px;
                    line-height: 40px;
                    border: 1px solid #dedede !important;
                }
                input{
                    height: 40px;
                    border-radius: 3px;
                    border:1px solid #ddd !important;
                    box-shadow: none !important;
                    font-weight:300;
                    font-size: 14px;
                    background-color: #f1f1f4;
                }
                span.dk-erploginInfo{
                    display: inline-block;
                    width: 35px;
                    height: 40px;
                    background: #d9dfe7;
                    text-align: center;
                    line-height: 38px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #7b7b7b;
                    border: 1px solid #cecece;
                    border-radius: 0 3px 3px 0;
                }
            }
            .dk-submitBtn{
                min-width: 200px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 16px;
                outline: none;
                margin-top: 5px;
                max-width: 200px;
                border-radius: 30px;
                padding: 10px 15px;
                background-color: #2d96b6;
                &:hover{
                    background: #5e737d;
                    transition: all 0.5s;
                    text-decoration: none;
                }
            }
            .dk-forgot{
                font-size: 13px;
                font-weight: 400;
                color: #418bbf;
            }
            .dk-submitBtn{
                text-align: center;
                a{
                    display: inline-block;
                    background:#1270b3;
                    border:none;
                    border-radius: 30px;
                    padding: 10px 15px;
                    width: 100%;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 16px;
                    outline: none;
                    margin-top:5px;
                    max-width: 200px;
                    &:hover{
                        background: #418bbf;
                        transition: all 0.5s;
                        text-decoration: none;
                    }
                }
            }
            .dk-loginTheree{
                .d-flex{
                    label{
                        &:first-child{
                            font-weight: 400;
                            font-size: 14px; 
                        }
                    }
                }
            }
        }
        .dk-formLoginFoot{
            position: relative;
            .dk-orLogin{
                position: relative;
                text-align: center;
                span{
                    background: #fff;
                    display: inline-block;
                    padding: 0 10px;
                    position: relative;
                    z-index: 9;
                    font-weight: 300;
                    font-size: 14px;
                }
                &::after{
                    content: '';
                    width: 80%;
                    height: 1px;
                    background: #ddd;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    top: 12px;
                    z-index: 1;
                }
            }
            .dk-socialAccountLogin{
                margin:25px 0;
                display: block;
                a{
                    padding:8px 25px;
                    padding-left:18px;
                    border-radius: 30px;
                    border:1px solid #ddd;
                    margin:0 8px;
                    color: #333;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    padding-right: 18px;
                    &:hover{
                        text-decoration: none;
                    }
                    img{
                        width: 22px;
                        margin-right:8px;
                    }
                }
            }
            .dk-signupText{
                text-align: center;
                font-weight: 300;
                display: block;
                padding:0;
                font-size: 14px;
                padding-left: 87px;
            }
        }
    }
}

.dk-loginheader{
    background: #f2f2f2;
    border-bottom: 1px solid #ddd;
    padding:6px 20px;
    .dk-headLogo{
        display: inline-block;
        width: 100%;
        max-width: 135px;
    }
}
.dk-signupMain{
    background: #fff;
    padding:15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 90vh;
    margin-top: 60px;
    @media(max-width:991px){
        padding: 15px;
    }
    @media(max-width:767px){
        height: auto;
    }

    .dk-createTypeMain{
        width: 100%;
        max-width: 600px;
        text-align: left;
        margin-top:30px;
        line-height: 45px;
        .selection.dropdown{
            &:focus{
                border-color: #ddd;
            }
            .menu{
                max-height: auto !important;
                min-width: 160px !important;
                border:none;
                .item{
                    padding: 10px 10px !important;
                    width: 100%;
                    display: block;
                    border-bottom: 1px solid #ddd;
                    &:last-child{
                        border-bottom: none;
                    }
                    .text{
                        font-size: 14px;
                        font-weight: 400;
                        padding: 0;
                        display: block;
                    }
                }
            }
        }
        .labeled.dropdown{
            font-size: 28px;
            font-weight: 500;
            color: #333;
            margin: 0 10px;
            .dropdown.icon{
                display: none;
            }
            .text{
                border-bottom: 1px solid #ddd;
                padding-bottom: 10px;
            }
            .menu.visible{
                padding: 15px;
                .search{
                    height: 38px;
                    border-radius: 5px;
                    border:1px solid #ddd;
                }
                .header{
                    display: none;
                }
                .scrolling.menu{
                    box-shadow: none !important;
                    border: none !important;
                    .item{
                        padding:8px 0 !important;
                        input[type="checkbox"]{
                            position: relative;
                            top: 1px;
                        }
                    }
                    .text{
                        border-bottom: none;
                        font-size: 16px;
                        padding: 0;
                        margin-left: 5px;
                    }
                }
            }
        }
        .selection.dropdown{
            display: inline-block;
            width:100%;
            max-width: fit-content;
            text-align: center;
            border: none;
            border-bottom: 1px solid #ddd;
            padding: 0;
            line-height: normal;
            margin: 0 10px;
            .dropdown.icon{
                display: none;
            }
            .text{
                font-size: 28px;
                color: #333 !important;
            }
        }
        span{
            font-size: 28px;
            font-weight: 100;
        }
    }
    h3.title{
        text-align: center;
        font-size:22px;
        font-weight:700;
        color: #333;
        width: 100%;
        margin-bottom: 18px;
        text-transform: uppercase;
        span.dk-infoModal{
            font-size: 18px;
            position: relative;
            bottom: 2px;
            left: 2px;
            cursor: pointer;
        }
    }
    .d-signUpFlex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        h4{
            display: block;
            width: 100%;
            margin-bottom: 20px;
            font-size: 15px;
            text-align: center;
        }
        .dk-crateExpBtn{
            min-width: 170px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 16px;
            outline: none;
            margin-top:45px !important;
            max-width: 200px;
            border-radius: 30px;
            padding: 10px 15px;
            background-color: #2d96b6;
            &:hover{
                background: #3f51b5;
                transition: all 0.5s;
            }
        }
        .dk-infoDesc{
            position: relative;
            input{
                border-radius:0;
                height: 38px;
            }
            input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color:#bbb;
            }
            input::-moz-placeholder { /* Firefox 19+ */
                color:#bbb;
            }
            input:-ms-input-placeholder { /* IE 10+ */
                color:#bbb;
            }
            input:-moz-placeholder { /* Firefox 18- */
                color:#bbb;
            }
            span{
                display: inline-block;
                width: 35px;
                height: 38px;
                background: #eaeaea;
                text-align: center;
                line-height: 38px;
                position: absolute;
                top: 0;
                right: 0;
                color:#7b7b7b;
                border:1px solid #cecece;
                border-radius:0 3px 3px 0;
            }
            
        }
    }
    .dk-signupContainer{
        width: 100%;
        max-width:450px;
        background:#F2F2F2;
        padding:10px 35px 20px;
        border-radius: 5px;
        border:1px solid #e8e8e8;
        .logo{
            text-align: center;
            margin-bottom:20px;
            img{
                width: 175px;
                height: 44px;
            }
        }
        .dk-removeIconBtn{
            button.dk-submitBtn{
                .MuiButton-endIcon{
                    display: none;
                }
            }
        }
        
        .mx-100{
            max-width: 100%;
        }
        h4{
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            margin-bottom: 15px;
            color: #676879;
        }

        form{
            width: 100%;
            max-width:100%;
            margin:15px auto;
            margin-bottom:8px;
            
            .signupNext{
                input{
                    padding-right: 150px;
                }
            }
            .dk-eyeICon{
                color: #928c8c;
                font-size: 14px;
                svg{
                    width:20px;
                }
            }
            label.dk-agreeTerms{
                input[type="checkbox"]{
                    position: relative;
                    top: 0;
                    margin-right:5px;
                    width: 15px;
                }
            }
            label{
                width: 100%;
                position: relative;
                margin-left: 1px;
                margin-bottom: 3px;
                color: #3a3a3a;
                input{
                    height: 38px;
                    border-radius: 3px;
                    border:1px solid #ddd !important;
                    box-shadow: none !important;
                    font-weight:400;
                    font-size: 14px;
                    background-color: #fff;
                }
                select{
                    height: 38px;
                    border-radius: 3px;
                    border:1px solid #ddd !important;
                    box-shadow: none !important;
                    font-weight:400;
                    font-size: 14px;
                    background-color: #fff;
                    appearance: none;
                    -webkit-appearance: none;
                    background-image: url('../../../assets/images/down-arrow.png');
                    background-repeat: no-repeat;
                    background-size: 11px;
                    background-position: 97%;
                    color: #333;
                }
                .divider.default{
                    color: #333;
                }
                .selection.dropdown{
                    border:1px solid #ddd !important;
                    &:hover{
                        border:1px solid #ddd !important;
                    }
                }
                .ui.dropdown .menu .active.item{
                    font-weight: 500;
                }
                .visible.menu{
                    border-color:#ddd !important;
                }
            }
            input{
                height: 38px;
                border-radius: 3px;
                border:1px solid #ddd !important;
                box-shadow: none !important;
                font-weight:400;
                font-size: 14px;
                background-color: #fff;
            }
            input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color:#bbb;
            }
            input::-moz-placeholder { /* Firefox 19+ */
                color:#bbb;
            }
            input:-ms-input-placeholder { /* IE 10+ */
                color:#bbb;
            }
            input:-moz-placeholder { /* Firefox 18- */
                color:#bbb;
            }
            .right-no-radius{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .domainAppendSpan{
                right: 0;
                top: 0;
                background: #d9dfe7;
                height: 38px;
                font-size: 13px;
                font-weight: 400;
                padding: 0 10px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                line-height: 40px;
                border: 1px solid #d6d6d6 !important;
                border-left: none !important;
                display: flex;
                align-items: center;
                i{
                    display: inline-block;
                    margin-left: 10px;
                    color: #7b7b7b;
                }
            }
            span.dk-eyeICon.domainAppendSpan{
                background:#fff !important;
            }
            span.domainAppendSpan.dk-stepOneDomain{
                padding-right: 0;
                background:#f2f2f2;
                i{
                    display: inline-block;
                    margin-left: 10px;
                    color: #7b7b7b;
                    width: 35px;
                    height: 36px;
                    background:#eaeaea;
                    text-align: center;
                    line-height: 39px;
                    border-left: 1px solid #cecece;
                    border-radius: 0 3px 3px 0;
                }
            }
            .dk-submitBtn{
                min-width: 170px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 16px;
                outline: none;
                margin-top: 5px;
                max-width: 200px;
                border-radius: 30px;
                padding: 10px 15px;
                background-color: #2d96b6;
                &:hover{
                    background: #5e737d;
                    transition: all 0.5s;
                    text-decoration: none;
                }
            }
            .dk-forgot{
                font-size: 13px;
                font-weight: 400;
                color: #418bbf;
            }
            .dk-submitBtn{
                text-align: center;
                a{
                    display: inline-block;
                    background:#1270b3;
                    border:none;
                    border-radius: 30px;
                    padding: 10px 15px;
                    width: 100%;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 16px;
                    outline: none;
                    margin-top:5px;
                    max-width: 200px;
                    &:hover{
                        background: #418bbf;
                        transition: all 0.5s;
                        text-decoration: none;
                    }
                }
            }
            .dk-loginTheree{
                .d-flex{
                    label{
                        &:first-child{
                            font-weight: 400;
                            font-size: 14px; 
                        }
                    }
                }
            }   
        }
        
        .dk-formSignupFoot{
            position: relative;
            .dk-orLogin{
                position: relative;
                text-align: center;
                span{
                    background: #fff;
                    display: inline-block;
                    padding: 0 10px;
                    position: relative;
                    z-index: 9;
                    font-weight: 300;
                    font-size: 14px;
                }
                &::after{
                    content: '';
                    width: 80%;
                    height: 1px;
                    background: #ddd;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    top: 12px;
                    z-index: 1;
                }
            }
            .dk-socialAccountLogin{
                margin:25px 0;
                display: block;
                a{
                    padding:8px 25px;
                    padding-left:18px;
                    border-radius: 30px;
                    border:1px solid #ddd;
                    margin:0 8px;
                    color: #333;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    padding-right: 18px;
                    &:hover{
                        text-decoration: none;
                    }
                    img{
                        width: 22px;
                        margin-right:8px;
                    }
                }
            }
            .dk-signupText{
                text-align: center;
                font-weight: 300;
                display: block;
                padding:0;
                font-size: 14px;
                padding-left: 87px;
            }
        }
    }
}

.dk-loginheader{
    background: #f2f2f2;
    border-bottom: 1px solid #ddd;
    padding:6px 20px;
    .dk-headLogo{
        display: inline-block;
        width: 100%;
        max-width: 135px;
    }
}
.tooltip.show .tooltip-inner {
    padding: 8px 10px;
    text-align: justify;
    line-height: 16px;
    max-width: 220px;
    background-color:#2d96b6;
}

.tooltip.show .arrow::before{
    border-top-color:#2d96b6;
}

.dk-infoModalMain{
    .modal-dialog{
        width: 100%;
        max-width:500px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 0 auto;
        .modal-content{
            box-shadow: none;
            border: none;
            border-radius: 5px;
            .modal-header{
                background: #2d96b6;
                padding:10px 15px;
                border-radius: 4px 4px 0 0;
                .modal-title{
                    display: block;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight:500;
                    margin: 0;
                }
                .close{
                    padding: 0;
                    margin: 0;
                    opacity: 1;
                    text-shadow: none;
                    font-weight: normal;
                    color: #fff;
                    font-size: 26px;
                    outline: none;
                    position: absolute;
                    right: 10px;
                    top: 13px;
                }
            }
            .modal-body{
                padding: 20px 35px;
            }
        }
    }
}